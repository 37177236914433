import Title from "../../components/Title";
import {useTranslation} from "react-i18next";
import "./PrivacyPolicy.css";

export default function PrivacyPolicy() {
    const { t} = useTranslation();

    return (
        <div>
            <Title title={t("PrivacyPolicy")} />

            <div className= "privacy-content">
                <div className= "privacy-paragraph">
                    {t("PrivacyLine1")}
                </div>
            </div>
        </div>
    )
}