import './App.css'
import React from 'react';
import Home from './pages/Home/Home';
import {Route, Routes} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "./pages/Terms/Terms";
import ContactUs from "./pages/ContactUs/ContactUs";
import Footer from "./components/footer/Footer";
import i18n from "i18next";
import { useState, useEffect } from 'react';

function App() {
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    const isArabic = currentLanguage === 'ar';

    useEffect(() => {
        // Update the dir attribute when the language changes
        document.body.dir = isArabic ? 'rtl' : 'ltr';
        document.body.className = currentLanguage;

        // Optionally: Listen for changes in the i18n language and update the state
        const languageChanged = (lng) => {
            setCurrentLanguage(lng);
        };

        i18n.on('languageChanged', languageChanged);

        // Cleanup: Unsubscribe from the event when the component is unmounted
        return () => {
            i18n.off('languageChanged', languageChanged);
        };

    }, [currentLanguage, isArabic]);

    const styles = {
        fontFamily: isArabic ? 'Cairo, sans-serif' : 'Open Sans, sans-serif',

    };

    return (
        <div style={styles}>
            <Navbar/>
            <div>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/en" element={<Home/>}/>
                    <Route path="/ar" element={<Home/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/terms-of-conditions" element={<Terms/>}/>
                    <Route path="/contact-us" element={<ContactUs/>}/>
                    <Route path="*" element={<Home/>}/>
                </Routes>
            </div>
            <Footer/>
        </div>
    )
}

export default App