import {useTranslation} from "react-i18next";
import Title from "../../components/Title";

import "../../pages/PrivacyPolicy/PrivacyPolicy.css";
export default function Terms() {
    const { t} = useTranslation();

    return (
        <div>
            <Title title={t("TermsAndConditions")} />

            <div className= "privacy-content">
                <div className= "privacy-paragraph">
                    {t("TermsLine1")}
                </div>
                <div className= "privacy-paragraph">
                    {t("TermsLine2")}
                </div>
                <div className= "privacy-paragraph">
                    {t("TermsLine3")}
                </div>
                <div className= "privacy-paragraph">
                    {t("TermsLine4")}
                </div>
                <div className= "privacy-paragraph">
                    <div dangerouslySetInnerHTML={{ __html: t("TermsLine5") }} ></div>
                </div>
                <div className= "privacy-paragraph">
                    {t("TermsLine6")}
                </div>
                <div className= "privacy-paragraph">
                    {t("TermsLine7")}
                </div>
                <div className= "privacy-paragraph">
                    {t("TermsLine8")}
                </div>
                <div className= "privacy-paragraph">
                    {t("TermsLine9")}
                </div>
                <div className= "privacy-paragraph">
                    {t("TermsLine10")}
                </div>
                <div className= "privacy-paragraph">
                    {t("TermsLine11")}
                </div>
                <div className= "privacy-paragraph">
                    {t("TermsLine12")}
                </div>
                <div className= "privacy-paragraph">
                   {t("TermsLine13")}
                </div>
                <div className= "privacy-paragraph">
                    {t("TermsLine14")}
                </div>
                <div className= "privacy-paragraph">
                    {t("TermsLine15")}
                </div>
                <div className= "privacy-paragraph">
                    {t("TermsLine16")}
                </div>


            </div>

        </div>


    )
}