import "./Home.css";
import phones from "../../assets/phones.webp"
import appstore from "../../assets/appstore.webp"
import googleplay from "../../assets/googleplay.webp"
import muscular from "../../assets/muscular-car-service.webp"
import {useTranslation} from "react-i18next";

export default function Home() {

    const { t} = useTranslation();
    return (
        <div>
            <div className={"image-container"}>
                <img src={muscular} alt="muscular" className="muscular" loading={"lazy"}/>
                <h1 className="title"> {t("HomeTitle")} </h1>
            </div>

            <div className="AboutContainer">
                <div className="centered">
                    <div className="AboutUs"> {t("AboutTitle")}</div>
                    <div className="parageaph">
                        <div dangerouslySetInnerHTML={{ __html: t("AboutParagraph") }}></div>
                    </div>
                </div>
            </div>

            <div className="AppContainer">
                <div>
                    <div className="App-title">{t("DownloadOurApp")}</div>
                    <img className="PhoneImg" src={phones} alt="PhoneImg" loading={"lazy"}/>
                </div>
            </div>
            <div className="download-bottons">
                <a className="download-link" href="https://apps.apple.com/kw/app/af-cars/id6459830385">
                <img className="downloadImg" src={appstore} alt="downloadImg" loading={"lazy"}/>
                </a>
                <a className="download-link" href="https://play.google.com/store/apps/details?id=com.line.afcars">
                    <img className="downloadImg" src={googleplay} alt="downloadImg" loading={"lazy"}/>
                </a>
            </div>


        </div>

    )

}