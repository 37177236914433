import {useTranslation} from "react-i18next";
import './ContactUs.css';
import Title from "../../components/Title";
import phone from "../../assets/phone.webp";
import mail from "../../assets/mail.webp";
import instagram from "../../assets/instagram.webp";

// FormComponent.js
import React, {useState} from 'react';
import {Button, ConfigProvider, Form, Input, message} from 'antd';
import TextArea from "antd/es/input/TextArea";
import * as emailjs from "emailjs-com";

export default function ContactUs() {
    const {t} = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Your message has been sent successfully',
        });
    };
    const errorMessage = () => {
        messageApi.open({
            type: 'error',
            content: 'There was an error sending your message',
        });
    };


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });


    const onFinish = (values) => {
        setLoading(true)
        console.log('Success:', values);
        //clear form
        setFormData({
            name: '',
            email: '',
            message: ''
        });
        values = {
            name: values.name,
            email: values.email,
            message: values.message
        }

        // Construct the email parameters based on the email template you set up in EmailJS
        let templateParams = {
            from_name: values.email,
            to_name: 'Your Email Address',
            message: JSON.stringify(values.message)
        };

        emailjs.send('service_dmlq2md', 'template_wvkhlom', templateParams, '6I9ss4bN_MJP4Q97F')
            .then((response) => {
                console.log('Email successfully sent!', response.status, response.text);
                success();
                setLoading(false)
            }, (error) => {
                console.log('Failed to send email.', error);
                errorMessage();
                setLoading(false)
            });

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className={'contact-us'}>
            {contextHolder}
            <Title title={t("ContactUs")}/>
            <div className="cards-container">
                <div className="card1">
                    <div className="contact-info">
                        <div className="contact-item-first">
                            <img src= {phone} alt="Phone" className="contact-icon" />
                            <p><div dangerouslySetInnerHTML={{ __html: t("CallUs") }} ></div></p>
                        </div>
                        <div className = "contact-txt">
                            <a href="tel:+96568888003">{t("Number1")}</a><br/> <a href="tel:+96568888003">{t("Number1")}</a>
                        </div>
                        <div className="contact-item">
                            <img src= {mail} alt="Email" className="contact-icon" />
                            <p><div dangerouslySetInnerHTML={{ __html: t("EmailUs") }} ></div></p>
                        </div>
                        <div className = "contact-txt">
                            afcars.kwt@gmail.com
                        </div>
                        <div className="contact-item">
                            <img src= {instagram} alt="Instagram" className="contact-icon" />
                            <p><div dangerouslySetInnerHTML={{ __html: t("Instagram") }} ></div></p>
                        </div>
                        <div className = "contact-txt">
                            <a href="https://www.instagram.com/afcars.kw">afcars.kw</a>
                        </div>
                    </div>
                </div>
                <div className="card2">
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: 'white',
                                fontSize: 'var(--step-0)',
                                fontFamily: 'Cairo, sans-serif',
                                colorText: 'white',
                                colorBgContainer: 'var(--secondary)',
                                colorTextContainer: 'white',
                                colorError: 'white',
                            },
                        }}
                    >
                        <Form
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}>
                            <p>{t("YourName")}</p>
                            <Form.Item
                                name="name"
                                rules={[{required: true, message: t("EnterName")}]}
                                style={{marginBottom: '1.5em'}}
                            >
                                <Input
                                    prefix={" "}
                                    placeholder={t("TypeHere")}
                                    name="name"
                                    value={formData.name}
                                />
                            </Form.Item>

                            <p>{t("Email")}</p>
                            <Form.Item
                                name="email"
                                rules={[
                                    {required: true, message: t("EnterEmail")},
                                    {type: 'email', message: t("InvalidEmail")}
                                ]}
                            >
                                <Input
                                    prefix={" "}
                                    placeholder={t("TypeHere")}
                                    name="email"
                                    value={formData.email}
                                />
                            </Form.Item>

                            <p>{t("Massage")}</p>
                            <Form.Item
                                name="message"
                                rules={[{required: true, message: t("EnterMassage")}]}
                            >
                                <TextArea
                                    prefix={" "}

                                    placeholder={t("TypeHere")}
                                    name="message"
                                    value={formData.message}

                                />
                            </Form.Item>

                            <Form.Item style={{textAlign: 'center'}}>
                                <Button loading={loading} type="primary" htmlType="submit"
                                        style={{
                                            width: '50%',
                                            height: '50px',
                                            backgroundColor: 'white',
                                            color: 'black',
                                            border: 'none',
                                            borderRadius: '10px',
                                        }}>
                                    {t("Submit")}
                                </Button>
                            </Form.Item>
                        </Form>
                    </ConfigProvider>

                </div>
            </div>
        </div>
    )
}