import {useTranslation} from "react-i18next";
import './Footer.css';
export default function Footer() {
    const { t} = useTranslation();


    return (
        <div className={"footer"}>
            <div>{t("FooterText1")}</div>
            <div>{t("FooterText2")}</div>
        </div>
    );
}