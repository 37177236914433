import { Link } from 'react-router-dom';
import {GlobalOutlined, MenuOutlined} from '@ant-design/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Navbar.css';
import logo from '../../assets/logo.webp';

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng).then(r => console.log(r));
        document.body.dir = lng === 'ar' ? 'rtl' : 'ltr';
        setMenuActive(false); // Close the menu when an item is clicked
    };


    const [menuActive, setMenuActive] = useState(false);
    const [activeItem, setActiveItem] = useState('Home');  // By default, Home is active

    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
        setMenuActive(false); // Close the menu when an item is clicked
    }



    return (
        <div className="navbar">
            <div className={`menu ${menuActive ? "active" : ""}`}>
                <div className={"language-button"} onClick={() => changeLanguage(i18n.language === 'en' ? 'ar' : 'en')}>
                    <GlobalOutlined />
                </div>
                <div>
                    <Link className={`menu-item ${activeItem === 'Home' ? 'active' : ''}`} onClick={() => handleItemClick('Home')} to="/home">{t('Home')}</Link>
                </div>
                <div >
                    <Link className={`menu-item ${activeItem === 'Privacy Policy' ? 'active' : ''}`} onClick={() => handleItemClick('Privacy Policy')} to="/privacy-policy">{t('PrivacyPolicy')}</Link>
                </div>
                <div >
                    <Link className={`menu-item ${activeItem === 'Terms of Conditions' ? 'active' : ''}`} onClick={() => handleItemClick('Terms of Conditions')} to="/terms-of-conditions">{t('TermsAndConditions')}</Link>
                </div>
                <div>
                    <Link className={`menu-item ${activeItem === 'Contact Us' ? 'active' : ''}`} onClick={() => handleItemClick('Contact Us')} to="/contact-us">{t('ContactUs')}</Link>
                </div>
                <div className={"menu-item no-display"} onClick={() => changeLanguage(i18n.language === 'en' ? 'ar' : 'en')}>
                    {t('ChangeLanguage')}
                </div>
            </div>
            <img className={`navbar-brand`} src={logo} alt="logo" />
            <div className={`${!menuActive ? "hamburger" : ""}`} onClick={() => setMenuActive(!menuActive)}>
                <MenuOutlined style={{color: "white"}} />
            </div>
        </div>
    );
};

export default Navbar;
