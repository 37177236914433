import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from '../translations/en.json';
import ar from '../translations/ar.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {translation: en},
            ar: {translation: ar}
        },
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    }).then(r => console.log(r));

 export default i18n;
